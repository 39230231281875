var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "overview-table",
        attrs: {
          options: _setup.gridOptions,
          headers: _setup.headers,
          "hide-default-footer": "",
          items: _setup.gridData,
          loading: _setup.gridIsLoading,
          "items-per-page": _setup.pageSize,
          "server-items-length": _setup.totalItems,
        },
        on: {
          "update:options": [
            function ($event) {
              _setup.gridOptions = $event
            },
            _setup.changedOptions,
          ],
        },
        scopedSlots: _vm._u(
          [
            {
              key: `item.details`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: "",
                        small: "",
                        color: "primary",
                        to: {
                          name: "ComplexDetails",
                          params: { addressId: item.id },
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v(" mdi-eye "),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: `item.demandHeatNet`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "v-layout",
                                  _vm._g(
                                    {
                                      staticClass:
                                        "d-flex align-center justify-space-between",
                                    },
                                    on
                                  ),
                                  [
                                    _c(
                                      "span",
                                      [
                                        _c("v-img", {
                                          attrs: {
                                            src: require("@/assets/img/" +
                                              _setup.getHouseImage(
                                                item.homesMeetingNetHeatRequirement,
                                                item.homesCount
                                              )),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    item.homesCount != 0
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _setup.calculatePercentage(
                                                  item.homesMeetingNetHeatRequirement,
                                                  item.homesCount
                                                )
                                              ) +
                                              " %"
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      item.homesCount != 0
                        ? _c("span", [
                            _vm._v(
                              "Percentage van het aantal woningen dat voldoet aan de warmtebehoefte: " +
                                _vm._s(
                                  _setup.calculatePercentage(
                                    item.homesMeetingNetHeatRequirement,
                                    item.homesCount
                                  )
                                ) +
                                " %"
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]
              },
            },
            {
              key: `item.sqm`,
              fn: function ({ item }) {
                return [
                  item.sqm != null
                    ? _c("span", [
                        _vm._v(
                          _vm._s(_setup.formatThousandNoFraction(item.sqm)) +
                            " m²"
                        ),
                      ])
                    : _vm._e(),
                ]
              },
            },
            {
              key: `item.dataScore.score`,
              fn: function ({ item }) {
                return [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _setup.formatThousandNoFraction(item.dataScore.score)
                      ) + "%"
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.label`,
              fn: function ({ item }) {
                return [
                  _c("energy-label", {
                    staticClass: "small",
                    attrs: {
                      label: item.label,
                      estimate: item.energyLabelIsEstimate,
                    },
                  }),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
      _c(_setup.GridPagination, {
        attrs: {
          pageNumber: _setup.pageNumber,
          pageSize: _setup.pageSize,
          pageCount: _setup.pageCount,
        },
        on: { changedPaging: _setup.changedPaging },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }